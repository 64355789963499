import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { bookingsStore, configStore } from "../state/CommonState";
import { OneDatetable, OneInput5table } from "../mytasks/act-common";
import { useEffect, useState } from "react";
import Api from "../state/Api";
import { DtStore } from "../dt/DtStore";
import { DtMap } from "../dt/DtMap";
import {origin_pickup_LCL,
  origin_pickup_fcl_FACTORY_FACTORY,
  origin_pickup_fcl_FACTORY_CFS,
  origin_pickup_fcl_CFS_FACTORY,
  origin_pickup_fcl_CFS_CFS,
  origin_pickup_AIR,
  destination_delivery_LCL,
  destination_delivery_fcl_FACTORY_FACTORY,
  destination_delivery_fcl_FACTORY_CFS,
  destination_delivery_fcl_CFS_CFS,
  destination_delivery_fcl_CFS_FACTORY,
  destination_delivery_AIR} from './dsr_static'

const  VEHICLE_ROW_ERROR_DEFAULT = {
  origin: true,
  destination: true,
  driver_number: true,
  vehicle: true,
  number_of_package: true,
  total_package: true,
};

export function Vmodal(props) {
  const booking = props.data;
  console.log("props-------->", booking.shipmentType, booking);
  
  let originDateSelector;
  if (booking.shipmentType === "LCL") {
    originDateSelector = origin_pickup_LCL;
  }
  if (booking.shipmentType === "FCL (Factory/Factory)") {
    originDateSelector = origin_pickup_fcl_FACTORY_FACTORY;
  }
  if (booking.shipmentType === "FCL (Factory/Dock)") {
    originDateSelector = origin_pickup_fcl_FACTORY_CFS;
  }
  if (booking.shipmentType === "FCL (Dock/Dock)") {
    originDateSelector = origin_pickup_fcl_CFS_CFS;
  }
  if (booking.shipmentType === "FCL (Dock/Factory)") {
    originDateSelector = origin_pickup_fcl_CFS_FACTORY;
  }
  if (booking.shipmentType === "Air") {
    originDateSelector = origin_pickup_AIR;
  }

  let destinationDateSelector;
  if (booking.shipmentType === "LCL") {
    destinationDateSelector = destination_delivery_LCL;
  }
  if (booking.shipmentType === "FCL (Factory/Factory)") {
    destinationDateSelector = destination_delivery_fcl_FACTORY_FACTORY;
  }
  if (booking.shipmentType === "FCL (Factory/Dock)") {
    destinationDateSelector = destination_delivery_fcl_FACTORY_CFS;
  }
  if (booking.shipmentType === "FCL (Dock/Dock)") {
    destinationDateSelector = destination_delivery_fcl_CFS_CFS;
  }
  if (booking.shipmentType === "FCL (Dock/Factory)") {
    destinationDateSelector = destination_delivery_fcl_CFS_FACTORY;
  }
  if (booking.shipmentType === "Air") {
    destinationDateSelector = destination_delivery_AIR;
  }
  console.log("destinationDateSelector", destinationDateSelector)
  useSnapshot(myactionsstore);
  useSnapshot(DtStore);
  useSnapshot(configStore);
  const [ch, setCh] = useState(0);
  const [origin, setOrgin] = useState([]);
  const [des, setDes] = useState([]);
  const [multipleDes, setMultipleDes] = useState([]);
  const [isPicked, setisPicked] = useState(false);
  const [mailPickup, setMailPickup] = useState(false);
  const [mailDelivery, setMailDelivery] = useState(false);
  const [isDelivered, setisDelivered] = useState(false);
  const [deliveryMailed, setDeliveryMailed] = useState(false);
  const [pickupMailed, setPickupMailed] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const [total_package, setTotalPackage] = useState(false);
  const [originError, setOriginError] = useState([]);
  const [destinationError, setDestinationError] = useState([]);
  const [multidestError, setMultiDestError] = useState([]);
  let ret = configStore.modalvisibledata;
  let st = ret.shipmentType;
  let sc = ret.shipmentScope;
  const multidest = booking?.booking?.contract?.multidest

  console.log("sc---------?", ret);
  console.log("originInitial---------?", booking.vorgin);
  console.log("originInitial else---------?", booking.vdes);
  useEffect(() => {
    let originInitial = new Array();
    if ((booking?.vorgin === undefined || booking?.vorgin?.length === 0) 
      && (sc === "Door-to-Port" || sc === "Door-to-Door")) {
      originInitial.push({
        origin: booking.origin,
        destination: booking.destination,
        driver_number: "",
        driver_name: "",
        vehicle: "",
        number_of_package: "",
        status: "",
        total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
        ...Object.keys(originDateSelector).reduce((ele, key) => {
          ele[key] = "";
          return ele;
        }, {}),
      })
    }else if (booking?.vorgin && booking?.vorgin?.length > 0 
      && (sc === "Door-to-Port" || sc === "Door-to-Door")) {
      originInitial = booking?.vorgin;
      originInitial.forEach((ele) => {
        ele.origin = ele.origin === undefined ? booking.origin : ele.origin;
        ele.destination = ele.destination === undefined ? booking.destination : ele.destination;
        ele.driver_number = ele.driver_number === undefined ? "" : ele.driver_number;
        ele.driver_name = ele.driver_name === undefined ? "" : ele.driver_name;
        ele.vehicle = ele.vehicle === undefined ? "" : ele.vehicle;
        ele.number_of_package = ele.number_of_package === undefined ? "" : ele.number_of_package;
        ele.status = ele.status === undefined ? "" : ele.status;
        ele.total_package = ele.total_package > 0 ? ele.total_package : 
        booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0);
      })
    }
    setOrgin(originInitial);
    setOriginError(originInitial.map(ele => VEHICLE_ROW_ERROR_DEFAULT))
    let destinationInitial = new Array();
    if ((booking?.vdes === undefined || booking?.vdes?.length === 0) && (sc == "Port-to-Door" || sc == "Door-to-Door")
      &&(multidest?.length === 1 || multidest === undefined)) {
        destinationInitial.push({
          origin: booking.origin,
          destination: booking.destination,
          driver_number: "",
          driver_name: "",
          vehicle: "",
          number_of_package: "",
          status: "",
          total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
          ...Object.keys(destinationDateSelector).reduce((ele, key) => {
            ele[key] = "";
            return ele;
          }, {}),
        })
    }else if (booking?.vdes?.length > 0 && (sc == "Port-to-Door" || sc == "Door-to-Door") && 
      (multidest === undefined || multidest?.length === 1)) {
      destinationInitial = booking?.vdes?.length > 0 ? booking?.vdes : multidest;
      destinationInitial.forEach((ele) => {
        ele.origin = ele.origin === undefined ? booking.origin : ele.origin;
        ele.destination = ele.destination === undefined ? booking.destination : ele.destination;
        ele.driver_number = ele.driver_number === undefined ? "" : ele.driver_number;
        ele.driver_name = ele.driver_name === undefined ? "" : ele.driver_name;
        ele.vehicle = ele.vehicle === undefined ? "" : ele.vehicle;
        ele.number_of_package = ele.number_of_package === undefined ? "" : ele.number_of_package;
        ele.status = ele.status === undefined ? "" : ele.status;
        ele.total_package = ele.total_package > 0 ? ele.total_package :
        booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0);
      })
    }
    setDes(destinationInitial);
    setDestinationError(destinationInitial.map(ele => VEHICLE_ROW_ERROR_DEFAULT))
    let multipleDestinationInitial = new Array();
    if((booking?.vdes === undefined || booking?.vdes.length === 0) && multidest?.length > 1 
    && (sc == "Port-to-Door" || sc == "Door-to-Door")){
      console.log("initial--------->")
      multidest.forEach((ele, index) => {
        let destination = [{
          origin: "",
          destination: ele.destination,
          driver_number: "",
          driver_name: "",
          vehicle: "",
          number_of_package: "",
          status: "",
          total_package: ele.cargoDetails.reduce((sum, cargo) => sum + cargo.numPackages, 0),
          ...Object.keys(destinationDateSelector).reduce((selector, key) => {
            selector[key] = "";
            return selector;
          }, {}),
        }];
        multipleDestinationInitial.push(destination)
      })
      // setMultiDestError(multidest?.map(e => [VEHICLE_ROW_ERROR_DEFAULT]))
    }
    else if(booking?.vdes && multidest?.length > 1 && (sc == "Port-to-Door" || sc == "Door-to-Door")){
      console.log("second initial--------->", booking, multidest)
      let a = []
      for (let i = 0; i < multidest?.length; i++) {
          if(i==0){
              if(booking.vdes != undefined && booking.vdes.length > 0) {
                  a.push(booking.vdes)
              }
          }else{
              let k = i+1
              if(booking['vdes'+k] != undefined && booking['vdes'+k].length > 0) {
                  a.push(booking['vdes'+k])
              }
          }
      }
      console.log("multipleDestinationInitial", a)
      multipleDestinationInitial = a;
    }
    console.log("multipleDestinationInitial----------", multipleDestinationInitial)
    console.log("multipleDestinationInitial----------", multipleDestinationInitial?.map(ele => ele.map(e => [VEHICLE_ROW_ERROR_DEFAULT])))
    setMultipleDes(multipleDestinationInitial)
    setMultiDestError(multipleDestinationInitial?.map(ele => ele.map(e => [VEHICLE_ROW_ERROR_DEFAULT])))
    
  }, [])

  const areAllCargoPickupFilled = () => {
    // Check if any cargo_pickup field is empty
    for (let i = 0; i < origin.length; i++) {
      if (!origin[i].cargo_pickup) {
        return false; // Return false if any cargo_pickup field is empty
      }
    }
    return true; // Return true if all cargo_pickup fields are filled
  };
  const areAllCargoDeliveryFilled = () => {
    // Check if any cargo_pickup field is empty
    for (let i = 0; i < des.length; i++) {
      if (!des[i].actual_delivery_date) {
        return false; // Return false if any cargo_pickup field is empty
      }
    }
    return true; // Return true if all cargo_pickup fields are filled
  };

  const handlePicked = () => {
    if (areAllCargoPickupFilled()) {
      setisPicked(!isPicked);
    } else {
      setMailPickup(false);
      setisPicked(false);
    }
  };

  const handlePickToggle = () => {
    if (areAllCargoPickupFilled()) {
      setMailPickup(!mailPickup);
    } else {
      setMailPickup(false);
    }
  };
  const handleDeliveryToggle = () => {
    if (areAllCargoDeliveryFilled()) {
      setMailDelivery(!mailDelivery);
    } else {
      setMailDelivery(false);
    }
  };
  const handleDelivered = () => {
    if (areAllCargoDeliveryFilled()) {
      setisDelivered(!isDelivered);
    } else {
      setisDelivered(false);
    }
  };
  const seorde = () => {
    setPickupMailed(ret.picked !== true);
    setDeliveryMailed(ret.delivered !== true);
    if (sc == "Port-to-Door" || sc == "Door-to-Door") {
      if (ret.vdes != undefined && ret.vdes.length > 0) {
        setDes(ret.vdes);
      }
    }
    if (sc == "Door-to-Port" || sc == "Door-to-Door") {
      if (ret.vorigin != undefined && ret.vorigin.length > 0) {
        setOrgin(ret.vorigin);
      }
    }
    changech();
  };

  const changech = () => {
    if (ch == 0) {
      setCh(1);
    } else {
      setCh(0);
    }
  };


  const add1 = () => {
    let o = origin;

    o.push({
      origin: booking.origin,
      destination: booking.destination,
      driver_number: "",
      driver_name: "",
      vehicle: "",
      number_of_package: "",
      status: "",
      total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
      ...Object.keys(originDateSelector).reduce((ele, key) => {
        ele[key] = "";
        return ele;
      }, {}),
    });
    console.log("...originError", originError, VEHICLE_ROW_ERROR_DEFAULT)
    setOriginError([...originError, VEHICLE_ROW_ERROR_DEFAULT]);
    setOrgin(o);
    // changech();
    // handlePickToggle();
  };

  const del1 = (id) => {
    let l = [];
    for (let i = 0; i < origin.length; i++) {
      if (i != id) {
        l.push(origin[i]);
      }
    }
    console.log("setOrgin---------> del1", l);
    setOrgin(l);
    let err = [];
    for (let i = 0; i < originError.length; i++) {
      if (i != id) {
        err.push(originError[i]);
      }
    }
    setOriginError([...err]);
  };

  const del2 = (id) => {
    let l = [];
    for (let i = 0; i < des.length; i++) {
      if (i != id) {
        l.push(des[i]);
      }
    }
    setDes(l);
    let err = [];
    for (let i = 0; i < destinationError.length; i++) {
      if (i != id) {
        err.push(destinationError[i]);
      }
    }
    setDestinationError([...err]);
  };

  const add2 = () => {
    if(des && des.length > 0){
      let d = des;
   
      d.push({
        origin: booking.origin,
        destination: booking.destination,
        driver_number: "",
        driver_name: "",
        vehicle: "",
        number_of_package: "",
        status: "",
        total_package: booking?.booking?.contract?.cargoList?.reduce((sum, cargo) => sum + (+cargo.numPackages), 0),
        ...Object.keys(destinationDateSelector).reduce((ele, key) => {
          ele[key] = "";
          return ele;
        }, {}),
      });
      setDestinationError([...destinationError, VEHICLE_ROW_ERROR_DEFAULT]);
    setDes(d);
    // changech();
    // handleDeliveryToggle();
    }
  }
  
  const addMultiDestination = (destinationIndex) => {
    let multipleDestination = [...multipleDes];
    multipleDestination[destinationIndex].push({
      origin: "",
      destination: multipleDestination[destinationIndex][0].destination,
      driver_number: "",
      driver_name: "",
      vehicle: "",
      number_of_package: "",
      status: "",
      total_package: multipleDestination[destinationIndex][0].total_package,
      ...Object.keys(destinationDateSelector).reduce((selector, key) => {
        selector[key] = "";
        return selector;
      }, {}),
    })
    setMultipleDes(multipleDestination);

    let validation = [...multidestError];
    validation[destinationIndex].push(VEHICLE_ROW_ERROR_DEFAULT)
    setMultiDestError(validation);
  }

  const removeMultiDestination = (destinationArray, index) => {
    let updatedDestination = [...multipleDes]
    updatedDestination[destinationArray] = updatedDestination[destinationArray].filter((_, i) => i !== index);
    setMultipleDes(updatedDestination);

    let validation = [...multidestError]
    validation[destinationArray] = validation[destinationArray].filter((_, i) => i !== index);
    setMultiDestError(validation);
  }

  const onsubmit2 = () => {
    console.log("myactionsstore-------->", myactionsstore);
    if (myactionsstore.error.length > 0) {
      myactionsstore.error = [];
    }
    let k = 0;
    let np = 0;
    configStore.picked = mailPickup;
    configStore.delivered = mailDelivery;
    if (origin.length > 0) {
      ret.vorigin = origin;
    } else {
      ret.vorigin = [];
    }
    if (des.length > 0) {
      let d = [];
      for (let i = 0; i < des.length; i++) {
        des[i]["total_package"] = ret.total_package;
        if (
          des[i]["number_of_package"] != undefined &&
          des[i]["number_of_package"].length > 0
        ) {
          np = np + Number(des[i]["number_of_package"]);
        }
        if (st.toLowerCase().indexOf("fcl") != -1) {
          if (des[i]["container_number_vehicle"]) {
            des[i]["container_number_vehicle"] =
              des[i]["container_number_vehicle"].toUpperCase();
            if (
              !/^[A-Z]{1,4}[0-9]{1,7}/.test(
                des[i]["container_number_vehicle"]
              ) ||
              des[i]["container_number_vehicle"].length != 11
            ) {
              k = 1;
              myactionsstore.error.push("container_number_vehicle" + i);
            }
          }
        }
        d.push(des[i]);
      }
      if (np != 0 && Number(ret.total_package) != np) {
        k = 1;
        myactionsstore.error.push("totalpackagenumber");
      }
      if (k == 0) {
        ret.vdes = des;
      }
    } else {
      ret.vdes = [];
    }
    if (k == 0) {
      configStore.modalvisibledata = ret;
      myactionsstore.vModalVisible = false;
    }
  };

  let formRows;
  if (booking?.booking?.originCount === "India") {
    formRows = origin;
  }
  if ((booking?.booking?.destinationCount === "India" && multidest?.length === 1) ||
  booking?.booking?.destinationCount === "India" && multidest === undefined) {
    formRows = des;
  }
  if (booking?.booking?.destinationCount === "India" && multidest?.length > 1) {
    formRows = multipleDes;
  }

  
  // const checkMultiDestinationValid = (formRows, outerIndex, index) => {
  //   let multidestValidation = validationError.map((outerArray) => 
  //     outerArray.map((innerObj) => ({ ...innerObj }))
  //   );
  //   let driverNumber = [];
  //   formRows.map((row) => row.map((ele) => driverNumber.push(ele.driver_number) ));
  //   let uniquePhNumber = new Set(driverNumber);
  //   multidestValidation[outerIndex][index].origin = formRows[outerIndex][index].origin !== "";
  //   multidestValidation[outerIndex][index].destination = formRows[outerIndex][index].destination !== "";
  //   multidestValidation[outerIndex][index].driver_number =
  //     formRows[outerIndex][index].driver_number !== "" &&
  //     formRows[outerIndex][index].driver_number.length === 10 && driverNumber.length === uniquePhNumber.size;
  //   multidestValidation[outerIndex][index].vehicle = formRows[outerIndex][index].vehicle !== "";

  //   if (formRows[outerIndex].length == 1) {
  //     multidestValidation[outerIndex][index].number_of_package =
  //       formRows[outerIndex][index].number_of_package !== "" &&
  //       formRows[outerIndex][index].number_of_package <=
  //       formRows[outerIndex][index].total_package;
  //     multidestValidation[outerIndex][index].total_package =
  //       formRows[outerIndex][index].total_package !== "" &&
  //       formRows[outerIndex][index].total_package > 0;
  //   }

  //   if (formRows[outerIndex].length > 1) {
  //     let number_of_package = formRows[outerIndex].reduce((sum, obj) => {
  //       return sum + (parseInt(obj.number_of_package) || 0);
  //     }, 0);
  //     multidestValidation[outerIndex][index].number_of_package =
  //       number_of_package <= +formRows[outerIndex][0].total_package &&
  //       formRows[outerIndex][index].number_of_package !== "" &&
  //       formRows[outerIndex][index].number_of_package <=
  //       formRows[outerIndex][index].total_package;
  //     multidestValidation[outerIndex][index].total_package =
  //       number_of_package <= +formRows[outerIndex][0].total_package &&
  //       formRows[outerIndex][index].total_package !== "" &&
  //       formRows[outerIndex][index].total_package > 0;
  //   }
  //   setValidationError(multidestValidation);
  //   return multidestValidation;
  // };

  // const checkValid = (formRows, index) => {
  //   let validation = validationError.map((innerObj) => ({ ...innerObj }))
  //   validation[index].origin = formRows[index].origin !== "";
  //   validation[index].destination =
  //     formRows[index].destination !== "";
  //   validation[index].driver_number =
  //     formRows[index].driver_number !== "" &&
  //     formRows[index].driver_number.length === 10;
  //   validation[index].vehicle = formRows[index].vehicle !== "";

  //   if (formRows.length == 1) {
  //     validation[index].number_of_package =
  //       formRows[index].number_of_package !== "" &&
  //       formRows[index].number_of_package <=
  //         formRows[index].total_package;
  //     validation[index].total_package =
  //       formRows[index].total_package !== "" &&
  //       formRows[index].total_package > 0;
  //   }

  //   if (formRows.length > 1) {
  //     let number_of_package = formRows.reduce((sum, obj) => {
  //       return sum + (parseInt(obj.number_of_package) || 0);
  //     }, 0);
  //     validation[index].number_of_package =
  //       number_of_package <= +formRows[0].total_package &&
  //       formRows[index].number_of_package !== "" &&
  //       formRows[index].number_of_package <=
  //         formRows[index].total_package;
  //     validation[index].total_package =
  //       number_of_package <= +formRows[0].total_package &&
  //       formRows[index].total_package !== "" &&
  //       formRows[index].total_package > 0;
  //   }
  //   setValidationError(validation);
  //   return validation;
  // };

  // const handleChange = (index, outerIndex) => {
  //   let error;
  //   if(multipleDes && multipleDes.length > 0){
  //     error = checkMultiDestinationValid(formRows, outerIndex, index);
  //   }else{
  //     error = checkValid(formRows, index);
  //   }
  //   console.log("error--------------", error)
  //   let checked = multipleDes && multipleDes.length > 0 ? 
  //   Object.values(error[outerIndex][index]).every((value) => value === true) :
  //   Object.values(error[index]).every((value) => value === true);
  //   if ((checked && (formRows[index].status === "" || formRows[index].status === "PENDING")) 
  //     || (checked && (formRows[outerIndex][index].status === "" || formRows[outerIndex][index].status === "PENDING"))) {
  //     Api.checkPhNumber(
  //       { driver_number: multipleDes && multipleDes.length > 0 ? 
  //         formRows[outerIndex][index].driver_number : formRows[index].driver_number },
  //       async (result) => {
  //         configStore.setModalMessage(result.data);
  //         if (result.status === "PENDING") {
  //           multipleDes && multipleDes.length > 0 ? 
  //           formRows[outerIndex][index] = result.status
  //           : formRows[index].status = result.status;
  //         }
  //         if (result.data === "ALLOWED") {
  //           multipleDes && multipleDes.length > 0 ? 
  //           formRows[outerIndex][index] = "APPROVED"
  //           : formRows[index].status = "APPROVED";
            
  //         }
  //         // if (result.status === "PENDING") {
  //         //   // Need to check mobile status
  //         //   formRows[index].status = result.status;
  //         // }
  //       }
  //     );
  //   } else if ((checked && formRows[index].status === "APPROVED") || checked && formRows[outerIndex][index].status === "APPROVED") {
  //     if(booking.tracking[index].tripDetails){
  //       DtStore.modalVisible2 = true;
  //       DtStore.MapUrl = booking.tracking[index].tripDetails.publiclink;
  //     } else{
  //       let trackingData = { bookingId: booking._id, entityId: booking.entityId, trackingRow: formRows[index] }
  //       Api.startTrip(trackingData, async (data) => {
  //           DtStore.MapUrl = data.result.publiclink;
  //           DtStore.modalVisible2 = true;
  //         }
  //       );
  //     }
  //   } 
  //   // else {
  //   //   configStore.setModalMessage("Please check all fields");
  //   // }
  // };

  const checkValidation = (route, key) =>{
    let formRows = route;
    let driverNumber = [];
      if(key === "origin"){
        origin.map((ele) => driverNumber.push(ele.driver_number))
      }
      if(key === "des"){
        des.map((ele) => driverNumber.push(ele.driver_number))
      }
      let uniquePhNumber = new Set(driverNumber);
      let errorArr = formRows.map(obj => ({
        origin: obj.origin !== "",
        destination: obj.destination !== "",
        driver_number: obj.driver_number.length === 10 && driverNumber.length === uniquePhNumber.size,
        vehicle: obj.vehicle !== "",
        number_of_package: obj.number_of_package > 0 && (+formRows.reduce((sum, obj) => {
          return sum + (parseInt(obj.number_of_package) || 0);
        }, 0)) <= (+obj.total_package),
      }))
      if(key === "origin"){
        setOriginError(errorArr)
      }
      if(key === "des"){
        setDestinationError(errorArr)
      } 
      return formRows.length > 0 && errorArr.every(obj => 
        Object.values(obj).every(value => value === true)
      );
  }

  function checkMultiDestinationValid(){
    if(multidest?.length > 1){
      let driverNumber = [];
      multipleDes.map((row) => row.map((ele) => driverNumber.push(ele.driver_number) ));
      let uniquePhNumber = new Set(driverNumber);
      let errorArr = multipleDes.map(innerArray =>
        innerArray.map(obj => ({
          origin: obj.origin !== "",
          destination: obj.destination !== "",
          driver_number: obj.driver_number.length === 10 && driverNumber.length === uniquePhNumber.size,
          vehicle: obj.vehicle !== "",
          number_of_package: obj.number_of_package > 0 && innerArray.reduce((sum, obj) => {
            return sum + (parseInt(obj.number_of_package) || 0);
          }, 0) <= (+obj.total_package),
        }))
      );
      console.log("errorArr---------->", errorArr)
      setMultiDestError(errorArr)
      return errorArr.every(innerArray =>
        innerArray.every(obj =>
          Object.values(obj).every(value => value === true)
        )
      );
    }
    return false;
  }

  const onsubmit = () => {
    console.log("2409LCLCEN05710016 onsubmit-------------->", formRows)

    // if(multidest !== undefined){
    //   const allStatusesValid = formRows.forEach((ele) => ele.every((obj) => obj.status.trim() !== ""))
    //   if (!allStatusesValid) {
    //     configStore.setModalMessage(
    //       "Please Track all Row to update consent driver"
    //     );
    //     return false;
    //   }
    // }else{
    //   const allStatusesValid = formRows.every((obj) => obj.status.trim() !== "");
    //   if (!allStatusesValid) {
    //     configStore.setModalMessage(
    //       "Please Track all Row to update consent driver"
    //     );
    //     return false;
    //   }
    // }
    let isVehicleRowError;
    if(sc == "Door-to-Door"){
      if(origin.length > 0 && des.length > 0){
        let checkOrigin = checkValidation(origin, "origin"); 
        let checkDestination = checkValidation(des, "des");
        isVehicleRowError = checkOrigin && checkDestination;
      }
      if(origin.length > 0 && multipleDes.length > 0){
        let checkOrigin = checkValidation(origin, "origin");
        let checkMultiDestination = checkMultiDestinationValid();
        isVehicleRowError = checkOrigin && checkMultiDestination;
      }
    }
    if(sc == "Door-to-Port"){
      if(origin.length > 0 || des.length > 0){
        let checkOrigin = checkValidation(origin, "origin"); 
        let checkDestination = checkValidation(des, "des");
        isVehicleRowError = checkOrigin || checkDestination;
      }
    }
    if(sc == "Port-to-Door"){
      if(des.length > 0 || multipleDes.length > 0){
        let checkDestination = checkValidation(des, "des");
        let checkMultiDestination = checkMultiDestinationValid();
        isVehicleRowError = checkDestination || checkMultiDestination;
      }
    }
    console.log("isVehicleRowError", sc, isVehicleRowError)
    if (isVehicleRowError) {
      console.log("origin-------------", origin)
      if (origin && origin.length > 0) {
        ret.vorgin = origin;
      } 
      if (des && des.length > 0) {
        ret.vdes = des;
      } 
      if (multipleDes && multipleDes.length > 0) {
        for (let i = 0; i < multipleDes.length; i++) {
          if(i==0){
            ret.vdes = multipleDes[i]
          }else{
            let l = i+1
            ret['vdes'+l] = multipleDes[i]
          }
        }
      }
      console.log("ret---------->", ret.vorgin)
      console.log("ret---------->", ret.vdes)
      console.log("ret---------->", ret)
      myactionsstore.vModalVisible = false;
      // Api.updateTracking(
      //   { bookingId: booking._id, trackingData: formRows },
      //   async (result) => {
      //     configStore.setModalMessage(result.msg);
      //   }
      // );
    } 
    else {
      configStore.setModalMessage("Please check all fields");
    }
  };
  function convertDateFormat(dateStr) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateFormatRegex.test(dateStr)) {
        return dateStr;
    }
    if (!dateStr) return "";
    const [day, month, year] = dateStr.split("-");
    return `${year}-${month}-${day}`;
  }
  let modalClass = "modal";
  if (myactionsstore.vModalVisible) modalClass += " is-active";
  console.log("2409LCLCEN05710016 origin", origin, originError)
  console.log("2409LCLCEN05710016 des", des, destinationError)
  console.log("2409LCLCEN05710016 multipleDes", multidest, multidestError)
  return (
    <>
        
        <div className={modalClass}>
          <div className="modal-background"></div>
          <div
            className="modal-card"
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ borderRadius: "10px", width: "90%" }}
          >
            <section className="modal-card-body">
              <div
                className="table-container"
                onClick={(e) => e.stopPropagation()}
              >
                {(sc == "Door-to-Port" || sc == "Door-to-Door") &&
                st != "FCL (Factory/Dock)" && origin && origin.length > 0 ? (
                  <>
                    <div>
                      <div class="px-5 py-2 flex align-center justify-between">
                        <p class="text-base font-semibold">Origin Pick-up</p>
                        <div>
                          <div class="flex align-center">
                            <p class="text-base font-semibold">
                              Total No. of Packages: {(+origin[0].total_package)}
                            </p>
                          </div>
                          {/* {number_of_package <= ret.total_package ? <></>  : 
                          (<p
                              style={{ color: "red" }}
                              class="text-sm text-red-500"
                            >
                              Sum of vehicle packages should be equal to total No.of Packages
                            </p>
                          )} */}
                        </div>
                        <div></div>
                      </div>
                      {origin.map((e, i) => {
                        return (
                          <div
                            id={i + "0"}
                            key={i + "0"}
                            class="mb-5 p-5 bg-custom-gray rounded-md"
                          >
                            <div class="flex justify-between">
                              <div class="flex align-center">
                                <p class="text-sm font-semibold text-blue">
                                  Driver Status:
                                </p>
                                {e.status !== "" ? (
                                  <button class="w-24 h-8 mx-2 p-1 rounded-full">
                                    {e.status}
                                  </button>
                                ) : (
                                  <button class="w-24 h-8 mx-2 p-1 rounded-full">
                                    {" - - - - - -"}
                                  </button>
                                )}
                              </div>
                              <div class="flex align-center">
                                <p class="text-sm font-semibold">
                                  Vehicle {i + 1}
                                </p>
                              </div>
                              <div class="flex">
                                {origin.length > 1 ? (
                                  <button
                                    onClick={() => {
                                      del1(i);
                                      console.log("setOrgin--------->", origin);
                                    }}
                                    className="ml-2 button is-danger is-inverted"
                                  >
                                    <span className="icon">
                                      <i className="fas fa-trash"></i>
                                    </span>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div class="flex py-2">
                              <div class="flex w-3/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Origin*</p>
                                  <OneInput5table
                                    disabled={
                                      booking?.booking?.originCount === "India"
                                    }
                                    name="origin"
                                    isValid={originError?.[i]?.origin}
                                    record={e}
                                    label="Origin"
                                    errorMsg="Origin is required"
                                  />
                                  {/* <input type="text"  class="border-solid border w-full rounded-md p-1.5"/> */}
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Destination*</p>
                                  <OneInput5table
                                    name="destination"
                                    isValid={originError?.[i]?.destination}
                                    record={e}
                                    label="Destination"
                                    errorMsg="Destination is required"
                                  />
                                </div>
                              </div>
                              <div class="flex w-2/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Vehicle Number*</p>
                                  <OneInput5table
                                    name="vehicle"
                                    isValid={originError?.[i]?.vehicle}
                                    record={e}
                                    label="Vehicle#"
                                    errorMsg="Vehicle is required"
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">No. of Packages*</p>
                                  <OneInput5table
                                    name="number_of_package"
                                    isValid={
                                      originError?.[i]?.number_of_package
                                    }
                                    record={e}
                                    label="Number Of Package"
                                    errorMsg="Sum of vehicle packages should be equal to total No.of Packages"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="flex px-2 py-2">
                              <div class="w-full px-2">
                                <p class="text-sm">Driver Number*</p>
                                <OneInput5table
                                  name="driver_number"
                                  isValid={originError?.[i]?.driver_number}
                                  record={e}
                                  label="Driver Number"
                                  errorMsg="Required & Must be Unique"
                                />
                              </div>
                              <div class="w-full p  x-2">
                                <p class="text-sm">Driver Name</p>
                                <OneInput5table
                                  name="driver_name"
                                  record={e}
                                  label="Driver Name"
                                />
                              </div>
                              {Object.entries(originDateSelector).map(
                                ([key, value], index) => (
                                  <div className="w-full px-2" key={index}>
                                    <p className="text-sm">{value}</p>
                                    <input
                                      type="date"
                                      value={convertDateFormat(e[key])}
                                      className="border-solid border w-full rounded-md p-1.5"
                                      id={key}
                                      name={key}
                                      onChange={(event) => {
                                          const newOrigin = origin.map((item, idx) =>
                                            idx === i ? { ...item, [key]: key === "cargo_pickup" ?  
                                              event.target.value === "" ? e[key] : event.target.value : event.target.value } : item
                                          );
                                          setOrgin(newOrigin);
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                            {/* <div class="mt-2 pr-4 flex justify-end">
                             <button
                                class="h-9 border-0 px-4 py-2 border-transparent text-white bg-blue font-semibold rounded-md 
                                                            hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleChange(i);
                                }}
                              >
                                {e.status === "APPROVED"
                                  ? "View Tracking"
                                  : "Track"}
                              </button>
                            </div> */}
                          </div>
                        );
                      })}

                      <div class="mt-2 flex justify-end">
                        <button
                          onClick={add1}
                          class="w-24 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                        >
                          + Add Row
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {(sc == "Port-to-Door" || sc == "Door-to-Door") && 
                st != "FCL (Dock/Factory)" && des && des.length > 0 ? (
                  <>
                    <div>
                    <div class="px-5 py-2 flex align-center justify-between">
                        <p class="text-base font-semibold">Destination Delivery</p>
                        <div>
                          <div class="flex align-center">
                            <p class="text-base font-semibold">
                              Total No. of Packages: {(+des[0].total_package)}
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      { des && des.length > 0 ? des.map((e, i) => {
                        return (
                          <div class="mb-5 p-5 bg-custom-gray rounded-md">
                            <div class="flex justify-between">
                              <div class="flex align-center">
                                <p class="text-sm font-semibold text-blue">
                                  Driver Status:
                                </p>
                                <button class="w-24 h-8 mx-2 p-1 rounded-full">
                                  {" - - - - - -"}
                                </button>
                              </div>
                              <div class="flex align-center">
                                <p class="text-sm font-semibold">
                                  Vehicle {i + 1}
                                </p>
                              </div>
                              <div class="flex">
                                {des.length > 1 ? (
                                  <button
                                    onClick={() => del2(i)}
                                    className="ml-2 button is-danger is-inverted"
                                  >
                                    <span className="icon">
                                      <i className="fas fa-trash"></i>
                                    </span>
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <div class="flex py-2">
                              <div class="flex w-3/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Origin*</p>
                                  <OneInput5table
                                    name="origin"
                                    isValid={destinationError[i].origin}
                                    record={e}
                                    label="Origin"
                                    errorMsg="Origin is required"
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Destination*</p>
                                  <OneInput5table
                                    disabled={
                                      booking?.booking?.destinationCount === "India"
                                    }
                                    name="destination"
                                    isValid={destinationError[i].destination}
                                    record={e}
                                    label="Destination"
                                    errorMsg="Destination is required"
                                  />
                                </div>
                              </div>
                              <div class="flex w-2/5">
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">Vehicle Number*</p>
                                  <OneInput5table
                                    name="vehicle"
                                    isValid={destinationError[i].vehicle}
                                    record={e}
                                    label="Vehicle#"
                                    errorMsg="Vehicle is required"
                                  />
                                </div>
                                <div class="w-2/4 px-4">
                                  <p class="text-sm">No. of Packages*</p>
                                  <OneInput5table
                                    name="number_of_package"
                                    isValid={
                                      destinationError[i].number_of_package
                                    }
                                    record={e}
                                    label="Number Of Package"
                                    errorMsg="Sum of vehicle packages should be equal to total No.of Packages"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="flex px-2 py-2">
                              <div class="w-full px-2">
                                <p class="text-sm">Driver Number*</p>
                                <OneInput5table
                                  name="driver_number"
                                  isValid={destinationError[i].driver_number}
                                  record={e}
                                  label="Driver Number"
                                  errorMsg="Required & Must be Unique"
                                />
                              </div>
                              <div class="w-full p  x-2">
                                <p class="text-sm">Driver Name</p>
                                <OneInput5table
                                  name="driver_name"
                                  record={e}
                                  label="Driver Name"
                                />
                              </div>
                              {Object.entries(destinationDateSelector).map(
                                ([key, value], index) => (
                                  <div className="w-full px-2" key={index}>
                                    <p className="text-sm">{value}</p>
                                    <input
                                      type="date"
                                      value={convertDateFormat(e[key])}
                                      className="border-solid border w-full rounded-md p-1.5"
                                      id={key}
                                      name={key}
                                      onChange={(event) => {
                                        const newdes = des.map((item, idx) =>
                                          idx === i ? { ...item, [key]: key === "cargo_pickup" ?  
                                            event.target.value === "" ? e[key] : event.target.value : event.target.value } : item
                                        );
                                        setDes(newdes)
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                            {/* <div class="mt-2 pr-4 flex justify-end">
                              <button
                                class="w-24 h-9 border-0 px-4 py-2 border-transparent text-white bg-blue font-semibold rounded-md 
                                                            hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleChange(i);
                                }}
                              >
                                Track {"-->"}
                              </button>
                            </div> */}
                          </div>
                        )
                      }) : <></>}
                      <div class="mt-2 flex justify-end">
                        <button
                          onClick={add2}
                          class="w-24 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                        >
                          + Add Row
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {(sc == "Port-to-Door" || sc == "Door-to-Door") && st != "FCL (Dock/Factory)"
                && multipleDes && multipleDes.length > 0 ? multipleDes.map((ele, index) =>{
                  return (
                    <div key={index + "1"}>
                      <div class="px-5 py-2 grid grid-cols-3 items-center w-full">
                        <p class="text-base font-semibold">
                          Destination Delivery: {index + 1} 
                        </p>
                          <p class="text-base font-semibold col-start-2 col-span-1 text-center">
                            Total No. of Packages: 
                            {(+multipleDes[index][0].total_package)}
                          </p>
                      </div>
                      {
                        ele.map((e, i) => {
                          return(
                            <div key={i+"11"}>
                              <div class="mb-5 p-5 bg-custom-gray rounded-md">
                                <div class="flex justify-between">
                                  <div class="flex align-center">
                                    <p class="text-sm font-semibold text-blue">
                                      Driver Status:
                                    </p>
                                    <button class="w-24 h-8 mx-2 p-1 rounded-full">
                                      {" - - - - - -"}
                                    </button>
                                  </div>
                                  <div class="flex align-center">
                                    <p class="text-sm font-semibold">
                                      Vehicle {i + 1}
                                    </p>
                                  </div>
                                  <div class="flex">
                                    {ele && ele.length > 1 ? (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          removeMultiDestination(index, i);
                                        }}
                                        className="ml-2 button is-danger is-inverted"
                                      >
                                        <span className="icon">
                                          <i className="fas fa-trash"></i>
                                        </span>
                                      </button>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                <div class="flex py-2">
                                  <div class="flex w-3/5">
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">Origin*</p>
                                      <OneInput5table
                                        name="origin"
                                        isValid={multidestError[index][i].origin}
                                        record={e}
                                        label="Origin"
                                        errorMsg="Origin is required"
                                      />
                                    </div>
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">Destination*</p>
                                      <OneInput5table
                                        disabled={
                                          booking?.booking?.destinationCount ===
                                          "India"
                                        }
                                        name="destination"
                                        isValid={multidestError[index][i].destination}
                                        record={e}
                                        label="Destination"
                                        errorMsg="Destination is required"
                                      />
                                    </div>
                                  </div>
                                  <div class="flex w-2/5">
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">Vehicle Number*</p>
                                      <OneInput5table
                                        name="vehicle"
                                        isValid={multidestError[index][i].vehicle}
                                        record={e}
                                        label="Vehicle#"
                                        errorMsg="Vehicle is required"
                                      />
                                    </div>
                                    <div class="w-2/4 px-4">
                                      <p class="text-sm">No. of Packages*</p>
                                      <OneInput5table
                                        name="number_of_package"
                                        isValid={ multidestError[index][i].number_of_package }
                                        record={e}
                                        label="Number Of Package"
                                        errorMsg="Sum of vehicle packages should be equal to total No.of Packages"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="flex px-2 py-2">
                                  <div class="w-full px-2">
                                    <p class="text-sm">Driver Number*</p>
                                    <OneInput5table
                                      name="driver_number"
                                      isValid={multidestError[index][i].driver_number}
                                      record={e}
                                      label="Driver Number"
                                      errorMsg="Required & Must be Unique"
                                    />
                                  </div>
                                  <div class="w-full p  x-2">
                                    <p class="text-sm">Driver Name</p>
                                    <OneInput5table
                                      name="driver_name"
                                      record={e}
                                      label="Driver Name"
                                    />
                                  </div>
                                  {Object.entries(destinationDateSelector).map(
                                    ([key, value], destinationDateSelectorIndex) => (
                                      <div className="w-full px-2" key={destinationDateSelectorIndex}>
                                        <p className="text-sm">{value}</p>
                                        <input
                                          type="date"
                                          value={convertDateFormat(e[key])}
                                          className="border-solid border w-full rounded-md p-1.5"
                                          id={key}
                                          name={key}
                                          onChange={(event) => {
                                            const newdes = multipleDes.map((outerItem, oIdx) =>
                                              oIdx === index 
                                                ? outerItem.map((item, iIdx) =>
                                                    iIdx === i
                                                      ? { ...item, [key]: key === "cargo_pickup" ?  
                                                        event.target.value === "" ? e[key] : event.target.value : event.target.value }
                                                      : item
                                                  )
                                                : outerItem
                                            );
                                            setMultipleDes(newdes)
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                                {/* <div class="mt-2 pr-4 flex justify-end">
                                  <button
                                    class="w-24 h-9 border-0 px-4 py-2 border-transparent text-white bg-blue font-semibold rounded-md 
                                                                hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleChange(i, index);
                                    }}
                                  >
                                    Track {"-->"}
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          )
                        })
                      }
                      <div class="mt-2 mb-2 flex justify-end">
                        <button
                          onClick={(e) => {
                            e.preventDefault(); 
                            addMultiDestination(index)
                          }}
                          class="w-24 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                        >
                          + Add Row
                        </button>
                      </div>
                    </div>
                  )
                })
                : <></> }
                <div class="mt-5 flex justify-end">
                  <button
                    class="w-24 mr-3 h-9 text-blue border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                    onClick={() => (myactionsstore.vModalVisible = false)}
                  >
                    Cancel
                  </button>
                  <button
                    class="w-24 ml-3 h-9 text-blue bg-blue border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md"
                    onClick={(e) => {
                      e.preventDefault();
                      onsubmit()
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* { mapVisible && <DtMap /> } */}
    </>
  );
  };

  
